import { months_passed, get_date_components } from 'components/modules/date';
import { IS_VALID } from 'components/modules/validation';

export const RECIPROCITY = {
		id: -1,
		user_id: '',
		term_year: '',
		term_name: '',
		status: '',
		submit_date: '0000-00-00 00:00:00',
		dob: '',
		gender: '',
		ssn: '',
		us_citizen: '',
		visa_type: '',
		provide_parent_info: '',
		addr_street: '',
		addr_city: '',
		addr_state: '',
		addr_zip: '',
		phone: '',
		hs_name: '',
		hs_city: '',
		hs_state: '',
		hs_grad_date: '',
		level_of_study: '',

		relationship: '',
		relationship_addr_street: '',
		relationship_addr_city: '',
		relationship_addr_state: '',
		relationship_addr_zip: '',
		relationship_us_citizen: '',
		relationship_visa_type: '',
		relationship_visa_type_other: '',
		relationship_lived_wi_12_months: '',
		relationship_employed: '',
		relationship_income_taxes: '',
		relationship_registered_vote: '',
		relationship_valid_license: '',
		self_lived_wi_12_months: '',
		self_live_in_wi: '',
		self_lived_wi_date: '',
		self_employed: '',
		self_income_taxes: '',
		self_registered_vote: '',
		self_valid_license: '',
		self_claim_dependent: '',
		self_he_enrolled: '',

		campuses: [],
		higher_ed: []
	},
	HIGHER_ED = {
		school_name: '',
		attended_from: '',
		attended_to: ''
	},
	adult_age = 25,
	colleges = {
		uw: [
			'UW-Eau Claire',
			'UW-Green Bay',
			'UW-La Crosse',
			'UW-Madison',
			'UW-Milwaukee',
			'UW-Oshkosh',
			'UW-Parkside',
			'UW-Platteville',
			'UW-River Falls',
			'UW-Stevens Point',
			'UW-Stout',
			'UW-Superior',
			'UW-Whitewater'
		],
		uw_colleges: [
			'UW-Eau Claire - Barron County',
			'UW-Green Bay - Manitowoc Campus',
			'UW-Green Bay - Marinette Campus',
			'UW-Green Bay - Sheboygan Campus',
			'UW-Milwaukee at Washington County',
			'UW-Milwaukee at Waukesha',
			'UW-Oshkosh, Fond du Lac Campus',
			'UW-Oshkosh, Fox Cities Campus',
			'UW-Platteville Baraboo Sauk County',
			'UW-Platteville Richland',
			'UW-Stevens Point at Marshfield',
			'UW-Stevens Point at Wausau',
			'UW-Whitewater at Rock County'
		],
		mn: [
			'University of Minnesota - Crookston',
			'University of Minnesota - Duluth',
			'University of Minnesota - Morris',
			'University of Minnesota - Twin Cities & Rochester'
		],
		mn_state: [
			'Bemidji State University',
			'Metropolitan State University',
			'Minnesota State University - Mankato',
			'Minnesota State University - Moorhead',
			'Saint Cloud State University',
			'Southwest Minnesota State University',
			'Winona State University'
		],
		mn_state_college: [
			'Anoka Ramsey Community College',
			'Central Lakes Community & Technical College',
			'Century Community & Technical College',
			'Fond du Lac Community College',
			'Hibbing Community & Technical College',
			'Inver Hills Community College',
			'Itasca Community College',
			'Lake Superior Community & Technical College',
			'Mesabi Community & Technical College',
			'Minneapolis Community & Technical College',
			'Minnesota State Community & Technical College',
			'Minnesota West Community & Technical College',
			'Normandale Community College',
			'North Hennepin Community College',
			'Northland Community & Technical College',
			'Rainy River Community College',
			'Ridgewater Community & Technical College',
			'Riverland Community & Technical College',
			'Rochester Community & Technical College',
			'Vermilion Community College'
		]
	},
	getAvailableTerms = () => {
		let d = get_date_components(),
			opts = [],
			head_transition_exception = d.month === 1 && d.year === 2025;

		if (d.month < 6) opts.push(`Spring ${d.year}`);
		if (d.month < 7) opts.push(`Summer ${d.year}`);

		if (head_transition_exception) opts = [];

		if (d.month >= 2 || head_transition_exception) {
			opts.push(`Fall ${d.year}`);
			opts.push(`Spring ${d.year + 1}`);
			opts.push(`Summer ${d.year + 1}`);
		}

		return opts;
	},
	blockers = (reciprocity, ssn_confirm) => {
		let {
				campuses,
				term_name,
				dob,
				gender,
				ssn,
				us_citizen,
				visa_type,
				provide_parent_info,
				addr_street,
				addr_city,
				addr_state,
				addr_zip,
				phone,
				hs_name,
				hs_city,
				hs_state,
				hs_grad_date,
				level_of_study,
				relationship,
				relationship_addr_street,
				relationship_addr_city,
				relationship_addr_state,
				relationship_addr_zip,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_he_enrolled,
				higher_ed
			} = reciprocity,
			strRelation = (relationship || '').toLowerCase(),
			requiredFields = [
				{
					str: 'Term',
					val: term_name
				},
				{
					str: 'Legal Sex',
					val: gender
				},
				{
					str: 'Date of Birth',
					val: dob
				},
				{
					str: 'US Social Security Number',
					val: ssn
				},
				{
					str: 'Are you a US Citizen?',
					val: us_citizen
				},
				{
					str: 'Do you live in Wisconsin?',
					val: self_live_in_wi
				},
				{
					str: 'Current Level of Study',
					val: level_of_study
				},
				{
					str: 'Phone',
					val: phone
				},
				{
					str: 'Address: Street',
					val: addr_street
				},
				{
					str: 'Address: City',
					val: addr_city
				},
				{
					str: 'Address: State',
					val: addr_state
				},
				{
					str: 'Address: Zip / Postal Code',
					val: addr_zip
				},
				{
					str: 'Where are you employed?',
					val: self_employed
				},
				{
					str: 'Have you filed a Wisconsin resident income tax return for the most recent tax year?',
					val: self_income_taxes
				},
				{
					str: 'Was Wisconsin the last place you registered to vote or voted?',
					val: self_registered_vote
				},
				{
					str: "Do you hold a valid Wisconsin driver's license?",
					val: self_valid_license
				},
				{
					str: 'Did your parent(s)/guardian(s) claim you as a dependent for the most recent tax year?',
					val: self_claim_dependent
				},
				{
					str: 'Have you physically resided full-time in Wisconsin for the past 12 months?',
					val: self_lived_wi_12_months
				},
				{
					str: "Have you been enrolled at any institutions of higher education during the twelve months prior to today's date?",
					val: self_he_enrolled
				},
				{
					str: 'High School Name',
					val: hs_name
				},
				{
					str: 'High School - City',
					val: hs_city
				},
				{
					str: 'High School - State',
					val: hs_state
				},
				{
					str: 'High School Graduation Date',
					val: hs_grad_date
				},
				{
					str: "Do you want to provide a parent's information?",
					val: provide_parent_info
				}
			],
			blockConditions = [
				{
					str: 'No campuses selected',
					val: !campuses.length
				},
				{
					str: 'Invalid date of birth',
					val: !IS_VALID.date_of_birth(dob)
				},
				{
					str: 'Social security number is invalid',
					val: !IS_VALID.ssn(ssn)
				},
				{
					str: "Confirm social security number doesn't match",
					val: ssn !== ssn_confirm
				},
				{
					str: 'Invalid answer to "When did you start living in Wisconsin?"',
					val:
						self_live_in_wi === "Yes, but I haven't always" &&
						(!self_lived_wi_date || months_passed(self_lived_wi_date) < 0)
				},
				{
					str: 'Phone is invalid',
					val: !IS_VALID.phone(phone)
				},
				{
					str: 'Invalid zip / postal code',
					val: ![0, 5, 10].includes(addr_zip.length)
				},
				{
					str: 'Invalid high school graduation date',
					val: !IS_VALID.grad_date(hs_grad_date)
				}
			];

		if (us_citizen === 'No') requiredFields.splice(5, 0, { str: 'Select Visa Type', val: visa_type });

		if (self_he_enrolled === 'Yes') {
			blockConditions.push({
				val: !higher_ed.length,
				str: 'Missing: higher education history'
			});
		}

		if (provide_parent_info === 'Yes') {
			requiredFields.push({ val: relationship, str: 'Chosen relationship' });

			if (relationship) {
				requiredFields.push({ val: relationship_addr_street, str: `${relationship}'s address: Street` });
				requiredFields.push({ val: relationship_addr_city, str: `${relationship}'s address: City` });
				requiredFields.push({ val: relationship_addr_state, str: `${relationship}'s address: State` });
				requiredFields.push({
					val: relationship_addr_zip,
					str: `${relationship}'s address: Zip / Postal Code`
				});
				requiredFields.push({ val: relationship_us_citizen, str: `Is your ${relationship} a U.S. citizen?` });

				if (relationship_us_citizen === 'No')
					requiredFields.push({ val: relationship_visa_type, str: 'Visa Type' });

				if (relationship_visa_type === 'Other') {
					requiredFields.push({
						val: relationship_visa_type_other,
						str: "Please explain 'Other' visa status"
					});
				}

				requiredFields.push({
					val: relationship_lived_wi_12_months,
					str: `Has your ${strRelation} physically resided full-time in Wisconsin for the past 12 months?`
				});
				requiredFields.push({ val: relationship_employed, str: `Where is your ${strRelation} employed?` });
				requiredFields.push({
					val: relationship_income_taxes,
					str: `Has your ${strRelation} filed a Wisconsin resident income tax return for the most recent tax year?`
				});

				requiredFields.push({
					val: relationship_registered_vote,
					str: `Was Wisconsin the last place your ${strRelation} registered to vote or voted?`
				});
				requiredFields.push({
					val: relationship_valid_license,
					str: `Does your ${strRelation} hold a valid Wisconsin driver's license?`
				});

				blockConditions.push({
					val: ![0, 5, 10].includes(relationship_addr_zip.length),
					str: `Invalid ${strRelation}'s address: zip / postal code`
				});
			}
		}

		return requiredFields
			.filter(x => !x.val?.length)
			.map(x => `Missing: ${x.str}`)
			.concat(blockConditions.filter(x => !!x.val).map(x => x.str));
	};
